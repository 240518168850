<template>
  <div class="comment-publish">
    <div class="comment-publish__header" @click="goMyAccount">
      <a-icon :style="{ color: '#8991A4', fontSize: '18px' }" type="left" />
      <span class="comment-publish__header__text">{{ $t('product.commentPublish.back') }}</span>
    </div>
    <div class="comment-publish__body">
      <div class="comment-publish__body__title">{{ $t('product.commentPublish.comment') }}</div>
      <div class="comment-publish__body__product">
        <div class="comment-publish__body__product__image">
          <img v-lazy="productInfo.imgUrl" />
        </div>
        <div class="comment-publish__body__product__detail">
          <div class="comment-publish__body__product__detail__name">
            {{ productInfo.goodsName }}
          </div>
          <div class="comment-publish__body__product__detail__sku">{{ productSkuInfo }}</div>
          <div class="comment-publish__body__product__detail__price">
            {{ productInfo.salePrice | formatMoney }}
          </div>
        </div>
      </div>
      <div class="comment-publish__body__comment-star">
        <div class="comment-publish__body__comment-star__title">
          {{ $t('product.commentPublish.rating') }}
        </div>
        <div class="comment-publish__body__comment-star__number">
          <a-rate v-model="starLevel" :allow-clear="false" />
        </div>
      </div>
      <div class="comment-publish__body__comment-text">
        <div class="comment-publish__body__comment-text__title">
          {{ $t('product.commentPublish.comment') }}
        </div>
        <div class="comment-publish__body__comment-text__content">
          <a-form-model
            ref="commentForm"
            layout="vertical"
            :model="commentFormData"
            :rules="commentRules"
          >
            <a-form-model-item prop="commentContent">
              <a-textarea
                v-model="commentFormData.commentContent"
                :max-length="200"
                :auto-size="{ minRows: 3, maxRows: 5 }"
              />
            </a-form-model-item>
            <div class="comment-publish__body__comment-text__content__length-limit">
              <span class="comment-publish__body__comment-text__content__length-limit--current">
                {{ (commentFormData.commentContent && commentFormData.commentContent.length) || 0 }}
              </span>
              <span class="comment-publish__body__comment-text__content__length-limit--max">
                /200
              </span>
            </div>
          </a-form-model>
        </div>
      </div>
      <div class="comment-publish__body__comment-upload-image">
        <div class="comment-publish__body__comment-upload-image__title">
          {{ $t('product.comment.writeCommentAddImage') }}
        </div>
        <div class="comment-publish__body__comment-upload-image__content">
          <div class="comment-publish__body__comment-upload-image__content-title">
            <!-- Add picture(optional)at most {{ commentImgMax }} pictures can be added({{
              commentImgs.length
            }}/{{ commentImgMax }}) -->
            {{ formatAddImagePlaceholder() }}
          </div>
          <div class="comment-publish__body__comment-upload-image__content-picture">
            <op-upload @change="handleUploadComomentImageChange" />
          </div>
        </div>
      </div>
      <div class="comment-publish__body__comment-summit">
        <op-button @click="publishComment">{{ $t('product.commentPublish.release') }}</op-button>
      </div>
    </div>
  </div>
</template>

<script>
// import { publishComment, getOrderProductInfo } from '@/api'
import { getOrderProductInfo, addProductCommentByLogin } from '@/api'
// import { localStorage } from '@/utils'
export default {
  components: {},
  props: {},
  data() {
    return {
      starLevel: 5,
      commentFormData: {},
      commentRules: {
        commentContent: [
          { required: true, message: 'Please input comment content', trigger: 'change' },
        ],
      },
      productInfo: {},
      commentImgMax: 9,
      commentImgs: []
    }
  },

  computed: {
    productSkuInfo() {
      return (
        this.productInfo.orderItemSpecsParamDTOList &&
        this.productInfo.orderItemSpecsParamDTOList.map(item => item.specsValueName).join(',')
      )
    },
  },

  created() {
    this.getOrderProductInfo()
  },

  methods: {
    formatAddImagePlaceholder() {
      return this.$t('product.comment.writeCommentAddImagePlaceholder').replace('$[X]', 9)
    },
    async getOrderProductInfo() {
      const { data } = await getOrderProductInfo({
        orderNo: this.$route.query.orderNo,
        orderItemNo: this.$route.query.orderItemNo,
      })
      this.productInfo = data || {}
    },
    handleUploadComomentImageChange(data) {
      this.commentImgs = data.map(item => item.url)
    },
    async publishComment() {
      // this.$refs.commentForm &&
      //   this.$refs.commentForm.validate(async valid => {
      //     if (valid) {
      //       const userInfo = localStorage.get('userInfo', {})
      //       const params = {
      //         goodsId: this.productInfo.goodsId,
      //         starLevel: this.starLevel,
      //         commentContent: this.commentFormData.commentContent,
      //         goodsStyle:
      //           this.productInfo.orderItemSpecsDTOList &&
      //           this.productInfo.orderItemSpecsDTOList.map(item => item.specsValueName).join(','),
      //         consumerId: userInfo.customerId,
      //         consumerEmail: userInfo.mail,
      //         consumerFirstName: userInfo.firstName,
      //         consumerLastName: userInfo.lastName,
      //       }
      //       const { message } = await publishComment(params)
      //       this.$message.success(message)
      //       this.goMyAccount()
      //     }
      //   })
      await this.$refs.commentForm.validate()
      const params = {
        goodsId: this.productInfo.goodsId,
        starLevel: this.starLevel,
        commentContent: this.commentFormData.commentContent,
        commentImg: this.commentImgs
      }
      const { message } = await addProductCommentByLogin(params)
      this.$message.success(message)
      this.goMyAccount()
    },
    goMyAccount() {
      this.$router.back(-1)
    },
  },
}
</script>
<style lang="less" scoped>
.comment-publish {
  &__header {
    display: flex;
    align-items: center;
    padding: 24px 24px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #8991a4;
    cursor: pointer;
    &__text {
      margin-left: 8px;
    }
  }
  &__body {
    background: #fff;
    &__title {
      padding: 16px 24px 24px;
      font-size: 22px;
      font-weight: 500;
      color: var(--color-title);
      border-bottom: 1px solid #f4f6f8;
    }
    &__product {
      display: flex;
      align-items: center;
      padding: 24px;
      &__image {
        width: 100px;
        height: 100px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &__detail {
        padding-left: 24px;
        &__name {
          font-size: 16px;
        }
        &__sku {
          margin: 16px 0;
          font-size: 16px;
        }
        &__price {
          font-size: 16px;
          color: var(--color-product-sale-price);
        }
      }
    }
    &__comment-star {
      display: flex;
      align-items: center;
      padding: 24px;
      &__title {
        width: 100px;
        color: var(--color-sub-title);
      }
      &__number {
        display: flex;
        padding-left: 24px;
        /deep/ .ant-rate {
          color: #fadb14;
        }
      }
    }
    &__comment-text {
      display: flex;
      padding: 24px 24px 0;
      &__title {
        width: 100px;
        color: var(--color-sub-title);
      }
      &__content {
        position: relative;
        flex: 1;
        padding-left: 24px;
        &__length-limit {
          position: absolute;
          right: 0;
          bottom: 12px;
          &--max {
            color: #9daac2;
          }
        }
      }
    }
    &__comment-upload-image {
      display: flex;
      padding: 24px;
      &__title {
        width: 100px;
        color: var(--color-sub-title);
      }
      &__content {
        padding: 0 0 0 24px;
        &-title {
          margin-bottom: 12px;
        }
      }
    }
    &__comment-summit {
      padding: 0 0 24px 148px;
    }
  }
}
</style>
